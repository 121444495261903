import React, { useEffect, useRef, useState } from "react";
import './Groupyouhavejoin.css';
import './yourgroups3.css';
import { imageUrl, makeGetRequest, makePostRequest, makePostRequestForAll } from "../../services/api";
import { URLS } from "../../constants";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";
import Joingrouppopup from "./Joingrouppopup";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ResourceCard from "../ShareInspiration.WebAppMate/ResourceCard";
import { decodeToken, getTime, getTimeDifference, toastifyMessage, handleFileDownload } from "../../utilities/CustomFunctions";
import GroupChat from "./GroupChat";
import RightSideGroup from "./RightSideGroup";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import unpin from "../../assets/Images/unpin.png"
import Multiselect from "multiselect-react-dropdown";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { getFilterOptions } from "../../store/reducers/searchReducer";
import { getFileIcon } from "../Chat.WebAppMate/Service";
import CryptoJS from 'crypto-js';
import Announce from "./Announce";
import ResourcePopup from "../Common.WebAppMate/ResourcePopup";
import Head from "../Layout.WebAppMate/head/Head";
import { Learningpillar } from "../Common.WebAppMate/Learningpillar";
const secretKey = process.env.REACT_APP_DATA_ENCRYPT_DESCRYP_KEY;

/**
 * Save Resources List page to show all my saved resources list
 *
 * @author krishna dobariya <krishna.webappmate@gmail.com>
 * created on: 12-08-2023
 */

const GroupYouhaveJoin = () => {

  const dispatch = useDispatch();
  let searchStore = useSelector((state) => state.search);
  const { filterOptions } = searchStore;
  const { learning_pillars } = filterOptions;
  const navigate = useNavigate()
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let getimage = imageUrl;
  let user = decodeToken().userAuth;
  let UserImage = decodeToken().userAuth.photo;
  const Navigate = useNavigate();
  const [groupId, setGroupId] = useState()
  const [spin, setSpin] = useState(false);
  const [joinGroupPopUp, setJoinGroupPopUp] = useState(false)
  const [resourceData, setResourceData] = useState([])
  const [page, setPage] = useState(1);
  const [groupDetail, setGroupDetail] = useState([])
  const menuRef = useRef(null);
  const [status, setStatus] = useState(false);
  const param = useParams();
  const [ismember, setIsMember] = useState(false);
  const [chnageWall, setChnageWall] = useState(true);
  const [joinTime, setJoinTime] = useState("");
  const [value, setValue] = useState("group_feed");
  const [resourceList, setResourceList] = useState([]);
  const [resource, setResource] = useState("");
  const [learningPillarSelected, setLearningPillarSelected] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [downSpin, setDownSpin] = useState(false);
  const [announceChatCount, setAnnounceChatCount] = useState(1);
  const [adminId, setAdminId] = useState("");
  const [announcement, setAnnouncement] = useState(false);
  const [messageId, setMessageId] = useState("")
  const [, setByNotification] = useState(false);
  const [resourcePopUp, setResourcePopUp] = useState(false)
  const [requestStatus, setRequestStatus] = useState("");
  const [groupStatus, setGroupStatus] = useState("");
  const [actionStatus, setActionStatus] = useState({
    action_group: "", action_id: ""
  });
 
  useEffect(() => {
    const param1 = queryParams.get('go');
    const chat = queryParams.get("passKey");
    if (chat === "chat") {
      setChnageWall(false)
      setValue("group_chat")
    }
    if (param1) {
      setByNotification(true);
    } else {
      setByNotification(false);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (param.id) {
      setSpin(true);
      if (!isNaN(param.id)) {
        setGroupId(param.id)
      } else {
        const encryptedString = atob(param.id); // Decode Base64
        const decryptedBytes = CryptoJS.AES.decrypt(encryptedString, secretKey);
        const decryptedValue = decryptedBytes.toString(CryptoJS.enc.Utf8);
        setGroupId(decryptedValue)
      }
      setSpin(false);
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [param.id]);

  useEffect(() => {
    if (groupId) {
      getAllResources()
      dispatch(getFilterOptions());
    }
    // eslint-disable-next-line
  }, [groupId]);

  // this function use for get the resource list
  const getAllResources = () => {
    makeGetRequest(URLS.getAllResources, null, null, null)
      .then((res) => {
        if (res.code === 200) {
          setResourceList(res.data);
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (groupId) {
      getShreResource();
    }
    // eslint-disable-next-line
  }, [groupId, page]);

  useEffect(() => {
    if (value === "group_feed" || value === "group_announcement" || value === "group_admin" || value === "group_event") {
      setPage(1);
      setResourceData([])
      setResource("")
      setKeyword("")
      setLearningPillarSelected([])
     console.log("value")
      getShreResource();
      
    }
  }, [value])




  // this function use for get resource
  const getShreResource = () => {
    if (page === 1) {
      setSpin(true);
    }
    else if (status) {
      setDownSpin(true)
    }
    const payload = {
      "group_id": groupId,
      "group_type": value,
      "keyword": keyword,
      "shared_by_id": [],
      "learning_pillar_id": learningPillarSelected,
      "page_number": page,
      "resource_type": resource,
      "start_date": (selectedStartDate && moment(selectedStartDate).format("YYYY-MM-DD")) || "",
      "end_date": (selectedEndDate && moment(selectedEndDate).format("YYYY-MM-DD")) || "",
    }
    console.log("payload  ",payload)
    makePostRequest(URLS.getShareResourceByGroup, null, payload, null)
      .then((res) => {
        console.log("group data", res.data)
        setIsMember(res?.data?.is_member)
        setGroupStatus(res.data.groupDetails.status)
        const arr = []
        res?.data?.groupDetails?.group_admin_users?.map((val) => {
          return arr.push(val.user_id)
        })
        setAdminId(arr)
        setRequestStatus(res?.data?.invite_group)
        setActionStatus({
          action_group: res?.data?.action_group, action_id: res?.data?.action_id
        });
        setGroupDetail(res?.data?.groupDetails)
        setSpin(false);
        setDownSpin(false)
        if (res?.data?.remaining_count === 1) {
          setStatus(true)
        } else {
          setStatus(false)
        }
        if (page === 1) {
          const save = [];
          const dump = [];
          res?.data?.save?.map((val) => {
            return save.push(val.resource_id)
          })
          for (var i = 0; i < res?.data?.response?.length; i++) {
            if (save.includes(res?.data?.response[i]?.resource_id)) {
              const updatedObject = {
                ...res?.data?.response[i],
                save: "yes"
              };
              dump.push(updatedObject)
            } else {
              const updatedObject = {
                ...res?.data?.response[i],
                save: "no"
              };
              dump.push(updatedObject)
            }
          }
          setResourceData(dump);

          if (value === "group_announcement" && (page === 1 || announceChatCount !== 0)) {
            setSpin(true);
            const data = {
              "item_per_page": 10,
              "page_number": page,
              "group_id": groupId
            }
            makePostRequest(URLS.getSharedChat, null, data, null)
              .then((res) => {
                setAnnounceChatCount(res?.data?.remaining_count)
                const dummy = [...dump, ...res?.data?.response]
                const sorted = dummy.sort((a, b) => {
                  const dateA = new Date(a.shared_date_time);
                  const dateB = new Date(b.shared_date_time);

                  return dateB - dateA;
                });
                setResourceData(sorted)
                // setAnnounceChat(res?.data?.response)
                setSpin(false);
              }).catch((error) => {
                console.log("error::", error)
              })
          }
          if (value === "group_feed" && (page === 1 || announceChatCount !== 0)) {
            setSpin(true);
            const data = {
              "item_per_page": 10,
              "page_number": page,
              "group_id": groupId
            }
            makePostRequest(URLS.shareInvitationofGroupFeed, null, data, null)
              .then((res) => {
                console.log("resourceData", res)
                setAnnounceChatCount(res?.data?.remaining_count)
                const dummy = [...dump, ...res?.data?.groupShare]
                const sorted = dummy.sort((a, b) => {
                  const dateA = new Date(a.shared_date_time);
                  const dateB = new Date(b.shared_date_time);
                  return dateB - dateA;
                });
                setResourceData(sorted);
                setSpin(false)
              }).catch((error) => {
                setSpin(false)
                console.log("shareInvitationGroup:::", error)
              })
          }
        }
        else {
          const save = [];
          const dump = [];
          res?.data?.save?.map((val) => {
            return save.push(val.resource_id)
          })
          const arr = [...resourceData, ...res?.data?.response]
          for (var j = 0; j < arr?.length; j++) {
            if (save.includes(arr[j].resource_id)) {
              const updatedObject = {
                ...arr[j],
                save: "yes"
              };
              dump.push(updatedObject)
            } else {
              const updatedObject = {
                ...arr[j],
                save: "no"
              };
              dump.push(updatedObject)
            }
          }
          setResourceData(dump);
          if (value === "group_feed" && (announceChatCount !== 0)) {
            setSpin(true);
            const data = {
              "item_per_page": 10,
              "page_number": page,
              "group_id": groupId
            }
            makePostRequest(URLS.shareInvitationofGroupFeed, null, data, null)
              .then((res) => {
                setAnnounceChatCount(res?.data?.remaining_count)
                const dummy = [...dump, ...res?.data?.groupShare]
                const sorted = dummy.sort((a, b) => {
                  const dateA = new Date(a.shared_date_time);
                  const dateB = new Date(b.shared_date_time);
                  return dateB - dateA;
                });
                setResourceData(sorted);
                setSpin(false)
              }).catch((error) => {
                setSpin(false)
                console.log("shareInvitationGroup:::", error)
              })
          }
          if (value === "group_announcement" && announceChatCount !== 0) {
            setSpin(true);
            const data = {
              "item_per_page": 10,
              "page_number": page,
              "group_id": groupId
            }
            makePostRequest(URLS.getSharedChat, null, data, null)
              .then((res) => {
                setAnnounceChatCount(res?.data?.remaining_count)
                const dummy = [...dump, ...res?.data?.response]
                const sorted = dummy.sort((a, b) => {
                  const dateA = new Date(a.shared_date_time);
                  const dateB = new Date(b.shared_date_time);

                  return dateB - dateA;
                });
                console.log("res?.data?.response", sorted)
                setResourceData(sorted)
                // setAnnounceChat(res?.data?.response)
                setSpin(false);
              }).catch((error) => {
                console.log("error::", error)
              })
          }
        }


      }).catch((error) => {
        setSpin(false)
        console.log("error::", error)
      })
  }


  const getShreResourceonsearch = () => {
    if (page === 1) {
      setSpin(true);
    }
    else if (status) {
      setDownSpin(true)
    }
    const payload = {
      "group_id": groupId,
      "group_type": value,
      "keyword": keyword,
      "shared_by_id": [],
      "learning_pillar_id": learningPillarSelected,
      "page_number": page,
      "resource_type": resource,
      "start_date": (selectedStartDate && moment(selectedStartDate).format("YYYY-MM-DD")) || "",
      "end_date": (selectedEndDate && moment(selectedEndDate).format("YYYY-MM-DD")) || "",
    }
    console.log("payload search",payload)
    makePostRequest(URLS.getShareResourceByGroup, null, payload, null)
      .then((res) => {
        console.log("group data", res.data)
        setIsMember(res?.data?.is_member)
        setGroupStatus(res.data.groupDetails.status)
        const arr = []
        res?.data?.groupDetails?.group_admin_users?.map((val) => {
          return arr.push(val.user_id)
        })
        setAdminId(arr)
        setRequestStatus(res?.data?.invite_group)
        setActionStatus({
          action_group: res?.data?.action_group, action_id: res?.data?.action_id
        });
        setGroupDetail(res?.data?.groupDetails)
        setSpin(false);
        setDownSpin(false)
        if (res?.data?.remaining_count === 1) {
          setStatus(true)
        } else {
          setStatus(false)
        }
        if (page === 1) {
          const save = [];
          const dump = [];
          res?.data?.save?.map((val) => {
            return save.push(val.resource_id)
          })
          for (var i = 0; i < res?.data?.response?.length; i++) {
            if (save.includes(res?.data?.response[i]?.resource_id)) {
              const updatedObject = {
                ...res?.data?.response[i],
                save: "yes"
              };
              dump.push(updatedObject)
            } else {
              const updatedObject = {
                ...res?.data?.response[i],
                save: "no"
              };
              dump.push(updatedObject)
            }
          }
          setResourceData(dump);

          if (value === "group_announcement" && (page === 1 || announceChatCount !== 0)) {
            setSpin(true);
            const data = {
              "item_per_page": 10,
              "page_number": page,
              "group_id": groupId
            }
            makePostRequest(URLS.getSharedChat, null, data, null)
              .then((res) => {
                setAnnounceChatCount(res?.data?.remaining_count)
                const dummy = [...dump, ...res?.data?.response]
                const sorted = dummy.sort((a, b) => {
                  const dateA = new Date(a.shared_date_time);
                  const dateB = new Date(b.shared_date_time);

                  return dateB - dateA;
                });
                setResourceData(sorted)
                // setAnnounceChat(res?.data?.response)
                setSpin(false);
              }).catch((error) => {
                console.log("error::", error)
              })
          }
          if (value === "group_feed" && (page === 1 || announceChatCount !== 0)&&resource=="") {
            setSpin(true);
            const data = {
              "item_per_page": 10,
              "page_number": page,
              "group_id": groupId
            }
            makePostRequest(URLS.shareInvitationofGroupFeed, null, data, null)
              .then((res) => {
                console.log("resourceData", res)
                setAnnounceChatCount(res?.data?.remaining_count)
                const dummy = [...dump, ...res?.data?.groupShare]
                const sorted = dummy.sort((a, b) => {
                  const dateA = new Date(a.shared_date_time);
                  const dateB = new Date(b.shared_date_time);
                  return dateB - dateA;
                });
                setResourceData(sorted);
                setSpin(false)
              }).catch((error) => {
                setSpin(false)
                console.log("shareInvitationGroup:::", error)
              })
          }
        }
        else {
          const save = [];
          const dump = [];
          res?.data?.save?.map((val) => {
            return save.push(val.resource_id)
          })
          const arr = [...resourceData, ...res?.data?.response]
          for (var j = 0; j < arr?.length; j++) {
            if (save.includes(arr[j].resource_id)) {
              const updatedObject = {
                ...arr[j],
                save: "yes"
              };
              dump.push(updatedObject)
            } else {
              const updatedObject = {
                ...arr[j],
                save: "no"
              };
              dump.push(updatedObject)
            }
          }
          setResourceData(dump);
          if (value === "group_feed" && (announceChatCount !== 0)&&resource=="") {
            setSpin(true);
            const data = {
              "item_per_page": 10,
              "page_number": page,
              "group_id": groupId
            }
            makePostRequest(URLS.shareInvitationofGroupFeed, null, data, null)
              .then((res) => {
                setAnnounceChatCount(res?.data?.remaining_count)
                const dummy = [...dump, ...res?.data?.groupShare]
                const sorted = dummy.sort((a, b) => {
                  const dateA = new Date(a.shared_date_time);
                  const dateB = new Date(b.shared_date_time);
                  return dateB - dateA;
                });
                setResourceData(sorted);
                setSpin(false)
              }).catch((error) => {
                setSpin(false)
                console.log("shareInvitationGroup:::", error)
              })
          }
          if (value === "group_announcement" && announceChatCount !== 0) {
            setSpin(true);
            const data = {
              "item_per_page": 10,
              "page_number": page,
              "group_id": groupId
            }
            makePostRequest(URLS.getSharedChat, null, data, null)
              .then((res) => {
                setAnnounceChatCount(res?.data?.remaining_count)
                const dummy = [...dump, ...res?.data?.response]
                const sorted = dummy.sort((a, b) => {
                  const dateA = new Date(a.shared_date_time);
                  const dateB = new Date(b.shared_date_time);

                  return dateB - dateA;
                });
                console.log("res?.data?.response", sorted)
                setResourceData(sorted)
                // setAnnounceChat(res?.data?.response)
                setSpin(false);
              }).catch((error) => {
                console.log("error::", error)
              })
          }
        }


      }).catch((error) => {
        setSpin(false)
        console.log("error::", error)
      })
     
  }
  // this fuction is used for accepting invitation--------
  const acceptInvitation = (id, status, group_id, groupName) => {
    // setSpin(true)
    const payload = {
      "action_id": id,
      "status": status
    };
    makePostRequestForAll(URLS.UpdateAccpedReject, payload)
      .then((res) => {
        toastifyMessage(res.message, "success");
        getShreResource();
        makePostRequest(URLS.markSingleNotification, null, { "notification_id": queryParams.get('go') }, null)
          .then((res) => {

          }).catch((error) => {
            console.log("error::::", error)
          })
      }).catch((error) => {

        console.log("error::::", error)

      }).catch((error) => {
        setSpin(false)
        console.log("error", error);
      });
  };
  useEffect(() => {
    setJoinTime(getTimeDifference(groupDetail?.created_at))
  }, [groupDetail])

  // those are hanlders
  const handlePillars = (selectedList) => {
    let arr = [];
   
    selectedList?.map((val, i) => arr.push(val.id));
    setLearningPillarSelected(arr);
    
    
  };

  //  this function handle resource
  const handleResource = (selectedList) => {
    if (selectedList?.length > 0) {
      const lastSelected = selectedList[selectedList?.length - 1];
      setResource(lastSelected.id);
    }
    else
    setResource("")
    
  };
  const handleChange =(e) =>
    {
        if (e.key === 'Enter') {
          getShreResourceonsearch();
          }

    }

  // this function handle scroll
  const handleScroll = () => {
    if (
      window.innerHeight + Math.round(document.documentElement.scrollTop) === document.documentElement.offsetHeight) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    document.body.classList.add("groupJoin");
    return () => {
      document.body.classList.remove("groupJoin");
    };
  }, [])

  // this function use for truncate the text
  function truncate(str, length = 11) {
    return str?.length > length ? str.slice(0, length) + '...' : str;
  }

  return (

    <>
      <Head title="Group Detail"
        content="Build and support your community by starting a group to share resources, start group conversations, organize events, and provide updates through the bulletin board." ></Head>
      {
        spin && <div className="sharepopupOuter backgroundOpacity1"><FourDotsLoader /></div>
      }
      {resourcePopUp === true ? (
        <ResourcePopup
          data={() => setResourcePopUp(!resourcePopUp)}
          userImg={getimage + UserImage}
          fullName={user?.firstName + " " + user?.lastName}
          id={groupDetail?.id}
          label={groupDetail?.group_name}
          type={adminId.includes(user.id) ? "Admin" : "Member"}
          value={groupDetail?.id}
          countryName= {groupDetail?.country_name}
        />
      ) : null}
      {(groupStatus && groupStatus === "enable") ?
        <>
          <div className="col-lg-10 col-md-12 col-sm-12 padding-row">
            <div className="your-main-group">
              <div className="your-grp row">
                <div className="col-lg-8 col-12">
                  <div className="chat-group-innere">
                    <div className="the-secret">
                      <h1 className="text-capitalize">{groupDetail?.group_name}</h1>
                      <p className="">Created {joinTime} </p>
                    </div>
                    <div className="text-curriculum11">
                      {Array.isArray(groupDetail?.tbl_group_learning_pillars) &&
                        groupDetail?.tbl_group_learning_pillars?.map((item, i) => Learningpillar(item?.tbl_learning_pillar_master?.name, i))}
                      <h2 className="createaddmembers ">{groupDetail?.no_of_user} Members</h2>
                    </div>
                    <div className="descTextDatainfo  square scrollbar-dusty-grass square thin">
                      <p className="pdesctext" dangerouslySetInnerHTML={{ __html: groupDetail?.about_group }}></p>
                    </div>
                    
                    {/* <div className="descTextDatainfo  square scrollbar-dusty-grass square thin">
                      
                        <p className="pdesctext mt-2">Group Join Permission:
                        {groupDetail.group_join_permission=="admin_approval"?"Join With Approval of Admin":"No Approval Required"}</p>
                    </div> */}
                    {groupDetail.url!="" &&
                    <>
                    <div className="descTextDatainfo  square scrollbar-dusty-grass square thin">
                    <div className="chat-group">
                    <div>
                    <a href={groupDetail.url} target="_blank" rel="noopener">
                  <button className="mt-3">Group Website</button></a></div>
                  </div>
                  </div>
                      {/* <p className="pdesctext">Group URL:<a href={groupDetail.url} target="_blank" rel="noopener">{groupDetail.url}</a></p>
                    </div> */}
                    </>}

                  </div>
                </div>
                <div className="col-lg-4 col-12 side-img">
                  <div className="slider-page float-end-custome mb-2">
                    <img src={groupDetail?.image} alt="group" loading="lazy" />
                  </div>
                  <div className="chat-group  mb-2 ">
                    <div>
                      {joinGroupPopUp === true ? (
                        <Joingrouppopup datar={() => setJoinGroupPopUp(!joinGroupPopUp)} value={groupId} forwardedref={menuRef} getSuggestion={() => { }} funcall={getShreResource} />
                      ) : null}
                    </div>
                    {
                      ismember && <button onClick={() => {
                        setResourcePopUp(true)
                      }} className="mx-2">Create Resource</button>
                    }
                    {
                      ismember ?
                        <>  {chnageWall ?
                          <button onClick={() => { setChnageWall(!chnageWall); setValue("group_chat"); }}>Group Chat</button>
                          : <button className="mb-4" onClick={() => { setChnageWall(!chnageWall); setValue("group_feed") }}>Resources</button>}</>
                        : <>{requestStatus === "Join" ? <button className="joins-group" onClick={() => setJoinGroupPopUp(true)}>Join Group</button>
                          : actionStatus?.action_group === "action" ? <> <button className="join-group "
                            onClick={() =>
                              acceptInvitation(actionStatus?.action_id, "accepted")
                            } >Accept </button>
                            <button className="join-group "
                              onClick={() =>
                                acceptInvitation(actionStatus?.action_id, "rejected")
                              }>Reject </button></>
                            : <button className="joins-group" > Request pending</button>
                        }</>
                    }
                    <div className="d-flex justify-content-center">
                  <button onClick={() => Navigate(`/group-members/${groupId}`)} className="mx-4 mt-2">Manage Group</button></div>
                  </div>
                  
                    
                </div>
              </div>
              <div className="blur-part">
                {
                  (chnageWall && groupDetail?.group_name) ? <>
                    <div className="grouptablayout">

                      <div className="d-md-block d-none">
                        <Paper square>
                          <Tabs value={value} textColor="primary"
                            indicatorColor="primary" onChange={(event, newValue) => {
                              setValue(newValue);
                              setPage(1);
                              setResourceData([]);
                              setResource("");
                              setKeyword("");
                              setStatus(false)
                            }} >
                            <Tab label="Group Feed" value="group_feed" />
                            <Tab label="Events" value="group_event" />
                            <Tab label={<span><span className="group-name">{truncate(groupDetail?.group_name)}</span> Bulletin Board</span>} value="group_announcement" />
                            <Tab label={<span><span className="group-name">{truncate(groupDetail?.group_name)}</span> Resources</span>} value="group_admin" />
                          </Tabs>
                        </Paper>
                      </div>
                      <div className="row">
                        <div className="col-md-12 d-md-none d-sm-block">
                          <div class="form-group">
                            <select class="form-control" id="exampleFormControlSelect1" onChange={(e) => {
                              setValue(e.target.value);
                              setPage(1);
                              setResourceData([]);
                              setStatus(false)
                            }} value={value}>
                              <option value="group_feed">Group Feeds</option>
                              <option value="group_event">Events</option>
                              <option value="group_announcement">{truncate(groupDetail?.group_name)} Bulletin Board</option>
                              <option value="group_admin">{truncate(groupDetail?.group_name)} Bulletin Board</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="partsearch">
                      <div className="searchpart-div">
                        {
                          value !== "group_announcement" && <div className="group-search-bar" id={`${value === "group_event" ? "" : "groupfeedsearchbox"}`}>
                            {
                              value !== "group_event" && <div className="input-search-div learninghiddenscroll">
                                <label>Select Resource</label>
                                <Multiselect
                                  options={resourceList}
                                  onSelect={handleResource}
                                  onRemove={handleResource}
                                  onKeyPressFn={() => { }}
                                  showCheckbox
                                  showArrow
                                  onSearch={function noRefCheck() { }}
                                  placeholder="Resource Type"
                                  displayValue="name"
                                  selectedValues={resource ? [resourceList.find(item => item.id === resource)] : []}
                                />
                              </div>
                            }
                            <div className="input-search-div">
                              <label>Keywords</label>
                              <input
                                type="text"
                                className="input-btn-icon-icon"
                                placeholder="Keyword Search"
                                onChange={(e) => setKeyword(e.target.value)}
                                value={keyword}
                                name="keywords"
                                onKeyPress={handleChange}
                              />
                            </div>
                            <div className="input-search-div learninghiddenscroll">
                              <label>Learning Pillar</label>
                              <Multiselect
                                options={learning_pillars}
                                onSelect={handlePillars}
                                onRemove={handlePillars}
                                showCheckbox
                                displayValue="name"
                                placeholder="Learning Pillar"
                                selectedValues={learning_pillars.filter((pillar) =>
                                  learningPillarSelected.includes(pillar.id)
                                )}
                              />
                            </div>
                            {
                              (value === "group_event") && <>
                                <div className="input-search-div">
                                  <label> Start Date</label>
                                  <DatePicker
                                    className="border-0 w-100 searchDate"
                                    id=""
                                    selected={selectedStartDate}
                                    onChange={(date) => setSelectedStartDate(date)}
                                    placeholderText="Start date"
                                    dateFormat="MM-dd-yyyy"
                                  />
                                </div>
                                <div className="input-search-div">
                                  <label> End Date</label>
                                  <DatePicker
                                    className="border-0 w-100 searchDate"
                                    id="date"
                                    selected={selectedEndDate}
                                    onChange={(date) => setSelectedEndDate(date)}
                                    placeholderText="End date"
                                    dateFormat="MM-dd-yyyy"
                                  />
                                </div>
                              </>
                            }
                            <button className="search-btn" onClick={() => {
                              setPage(1);
                              setResourceData([])
                              getShreResourceonsearch();
                            }}>
                              <i className="fa fa-search"></i>
                            </button>
                          </div>
                        }
                      </div>
                    </div>
                  </> : <div className="partsearch borderpartremove"></div>
                }
                <div className="row chat-main">
                  {
                    chnageWall ?
                      <>
                        <div className="col-lg-8">
                          {resourceData?.length ? resourceData?.map((val) => {
                            return (
                              <>
                                {val.resource_id ? <ResourceCard value={val} userImg={getimage + UserImage}
                                  groupId={value === "group_announcement" ? groupId : ""}
                                  funcall={() => { setPage(1); getShreResource() }}
                                  status="group"
                                  admin={adminId.includes(user.id) ? "Admin" : "Member"}
                                  group_id={groupDetail?.id}
                                />
                                  : <>
                                    {val.group_id ? <>
                                      <div className="inspiration-div">
                                        <div className="inspiration-inner-div">
                                          <div className="d-flex gap-3">
                                            <img className="img-fluid" src={val?.group_shared_by_user?.photo} alt="matched user" />
                                            <h3 className="text_capital"><strong className="friendInspirationcapital">{val?.group_shared_by_user?.name}</strong> shared the group : <br />
                                            </h3>
                                            <label></label>
                                          </div>
                                        </div>
                                        <div className="SummerCamp">
                                          <div className="d-flex">
                                            {
                                              <div className="img-Facilitiess" style={{ cursor: "pointer" }} onClick={() => navigate(`/groupjoin/${btoa(CryptoJS.AES.encrypt(val.group_id.toString(), secretKey).toString())}`)}>
                                                <img src={val?.group_detail?.image} alt="MatchED group name" className="curriculum-image2" />
                                              </div>
                                            }
                                            <div className="summer-text">
                                              <h3 className="share-title" onClick={() => navigate(`/groupjoin/${btoa(CryptoJS.AES.encrypt(val.group_id.toString(), secretKey).toString())}`)} >{val?.group_detail?.group_name}</h3>
                                              <div className=" description-sharee" dangerouslySetInnerHTML={{ __html: val?.group_detail?.about_group }}>
                                              </div>
                                              <div className="text-curriculum11 mt-2">

                                                {Array.isArray(val?.group_detail?.tbl_group_learning_pillars) &&
                                                  val?.group_detail?.tbl_group_learning_pillars?.map((item, i) => Learningpillar(item?.tbl_learning_pillar_master?.name, i))}
                                              </div>
                                              {val?.isJoin === true ?
                                                <div>
                                                  <span className="reviews" onClick={() => navigate(`/groupjoin/${btoa(CryptoJS.AES.encrypt(val.group_id.toString(), secretKey).toString())}`)}>Join Group</span>
                                                </div>
                                                : ""}
                                            </div>
                                          </div>
                                        </div>
                                      </div >
                                    </>
                                      : <div className="inspiration-div rounded">
                                        <div className="inspiration-inner-div">
                                          <div className="SummerCamp-2 ">
                                            <div className="d-flex">
                                              <div className="img-fuild anncounce" style={{ cursor: "pointer" }} >
                                                <img src={val.send_by_user_photo} className="" alt="user" loading="lazy" />
                                              </div>
                                              <div className="summer-text pb-2">
                                                <h3 >{val?.send_by_name} <span className="ms-2">{getTime(val?.shared_date_time, "accounce")}</span></h3>
                                                <div>
                                                  {
                                                    val?.fileExtension === "png" ||
                                                      val?.fileExtension === "jpg" ||
                                                      val?.fileExtension === "jpeg" ||
                                                      val?.fileExtension === "PNG" ? <div> <img src={val?.chat_doc} alt="img" loading="lazy" className="announce-img my-1" onClick={() =>
                                                        handleFileDownload(
                                                          val.chat_doc,
                                                          "MatchEd_doc"
                                                        )
                                                      } /> </div> : <div onClick={() =>
                                                        handleFileDownload(
                                                          val.chat_doc,
                                                          "MatchEd_doc"
                                                        )
                                                      }>{val.fileExtension
                                                        ? getFileIcon(val.fileExtension)
                                                        : ""}</div>
                                                  }
                                                </div>
                                                <div className="">
                                                  {val?.message}
                                                </div>
                                              </div>
                                              <div className="unpin" onClick={() => { setAnnouncement(true); setMessageId(val.massageUniqueId) }}>
                                                <img src={unpin} alt="unpin" loading="lazy" />
                                              </div>
                                            </div>
                                          </div>

                                        </div>
                                      </div>
                                    }
                                  </>
                                }
                              </>
                            );
                          }) : <h1 className="text-center">No Data Found</h1>}
                          {
                            downSpin && <div className="share-spiner">
                              <FourDotsLoader />
                            </div>
                          }
                        </div>
                      </>
                      :
                      <>
                        <div className="d-md-block d-none grouptablayout">
                          <Paper square>
                            <Tabs value={value} textColor="primary"
                              indicatorColor="primary" onChange={(event, newValue) => {
                                setValue(newValue);
                                setPage(1);
                                setResourceData([]);
                                setStatus(false)
                              }} >
                              <Tab label="Group Feed" value="group_feed" />
                              <Tab label="Events" value="group_event" />
                              <Tab label={<span><span className="group-name">{truncate(groupDetail?.group_name)}</span> Bulletin Board</span>} value="group_announcement" />
                              <Tab label={<span><span className="group-name">{truncate(groupDetail?.group_name)}</span> Resources</span>} value="group_admin" />
                              <Tab label={<span><span className="group-name"></span>Group Chat</span>} value="group_chat" />
                            </Tabs>
                          </Paper>
                        </div>
                        <div className="row">
                          <div className="col-md-12 d-md-none d-sm-block">
                            <div class="form-group">
                              <select class="form-control" id="exampleFormControlSelect1" onChange={(e) => {
                                setValue(e.target.value);
                                setPage(1);
                                setResourceData([]);
                                setStatus(false)
                              }} value={value}>
                                <option value="group_feed">Group Feeds</option>
                                <option value="group_event">Events</option>
                                <option value="group_announcement">{truncate(groupDetail?.group_name)} Bulletin Board</option>
                                <option value="group_admin">{truncate(groupDetail?.group_name)} Bulletin Board</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="partsearch">
                          <div className="searchpart-div">
                            {
                              value !== "group_announcement" && value !== "group_chat" && <div className="group-search-bar" id={`${value === "group_event" ? "" : "groupfeedsearchbox"}`}>
                                {
                                  value !== "group_event" && value !== "group_chat" && <div className="input-search-div learninghiddenscroll">
                                    <label>Select Resource</label>
                                    <Multiselect
                                      options={resourceList}
                                      onSelect={handleResource}
                                      onRemove={handleResource}
                                      onKeyPressFn={() => { }}
                                      showCheckbox
                                      showArrow
                                      onSearch={function noRefCheck() { }}
                                      placeholder="Resource Type"
                                      displayValue="name"
                                      selectedValues={resource ? [resourceList.find(item => item.id === resource)] : []}
                                    />
                                  </div>
                                }
                                {value !== "group_chat" &&
                                  <div className="input-search-div">
                                    <label>Keywords</label>
                                    <input
                                      type="text"
                                      className="input-btn-icon-icon"
                                      placeholder="Keyword Search"
                                      onChange={(e) => setKeyword(e.target.value)}
                                      value={keyword}
                                      name="keywords"
                                    />
                                  </div>}

                                {value !== "group_chat" &&
                                  <div className="input-search-div learninghiddenscroll">
                                    <label>Learning Pillar</label>
                                    <Multiselect
                                      options={learning_pillars}
                                      onSelect={handlePillars}
                                      onRemove={handlePillars}
                                      showCheckbox
                                      displayValue="name"
                                      placeholder="Learning Pillar"
                                    />
                                  </div>}
                                {
                                  (value === "group_event") && <>
                                    <div className="input-search-div">
                                      <label> Start Date</label>
                                      <DatePicker
                                        className="border-0 w-100 searchDate"
                                        id=""
                                        selected={selectedStartDate}
                                        onChange={(date) => setSelectedStartDate(date)}
                                        placeholderText="Start date"
                                        dateFormat="MM-dd-yyyy"
                                      />
                                    </div>
                                    <div className="input-search-div">
                                      <label> End Date</label>
                                      <DatePicker
                                        className="border-0 w-100 searchDate"
                                        id="date"
                                        selected={selectedEndDate}
                                        onChange={(date) => setSelectedEndDate(date)}
                                        placeholderText="End date"
                                        dateFormat="MM-dd-yyyy"
                                      />
                                    </div>
                                  </>
                                }
                                {value !== "group_chat" &&
                                  <button className="search-btn" onClick={() => {
                                    setPage(1);
                                    setResourceData([])
                                    getShreResource();
                                  }}>
                                    <i className="fa fa-search"></i>
                                  </button>
                                }
                              </div>
                            }
                          </div>
                        </div>
                        <div className="col-lg-8">
                          {value !== "group_chat" &&
                            <>
                              {resourceData?.length ? resourceData?.map((val) => {
                                return (
                                  <>
                                    {val.resource_id ? <ResourceCard value={val} userImg={getimage + UserImage}
                                      groupId={value === "group_announcement" ? groupId : ""}
                                      funcall={() => { setPage(1); getShreResource() }}
                                      status="group"
                                      admin={adminId.includes(user.id) ? "Admin" : "Member"}
                                      group_id={groupDetail?.id}
                                    />
                                      : <>
                                        {val.group_id ? <>
                                          <div className="inspiration-div">
                                            <div className="inspiration-inner-div">
                                              <div className="d-flex gap-3">
                                                <img className="img-fluid" src={val?.group_shared_by_user?.photo} alt="matched user" />
                                                <h3 className="text_capital"><strong className="friendInspirationcapital">{val?.group_shared_by_user?.name}</strong> shared the group : <br />
                                                </h3>
                                                <label></label>
                                              </div>
                                            </div>
                                            <div className="SummerCamp">
                                              <div className="d-flex">
                                                {
                                                  <div className="img-Facilitiess" style={{ cursor: "pointer" }} onClick={() => navigate(`/groupjoin/${btoa(CryptoJS.AES.encrypt(val.group_id.toString(), secretKey).toString())}`)}>
                                                    <img src={val?.group_detail?.image} alt="MatchED group name" className="curriculum-image2" />
                                                  </div>
                                                }
                                                <div className="summer-text">
                                                  <h3 className="share-title" onClick={() => navigate(`/groupjoin/${btoa(CryptoJS.AES.encrypt(val.group_id.toString(), secretKey).toString())}`)} >{val?.group_detail?.group_name}</h3>
                                                  <div className=" description-sharee" dangerouslySetInnerHTML={{ __html: val?.group_detail?.about_group }}>
                                                  </div>
                                                  <div className="text-curriculum11 mt-2">
                                                    {Array.isArray(val?.group_detail?.tbl_group_learning_pillars) &&
                                                      val?.group_detail?.tbl_group_learning_pillars?.map((item, i) => Learningpillar(item?.tbl_learning_pillar_master?.name, i))}
                                                  </div>
                                                  {val?.isJoin === true ?
                                                    <div>
                                                      <span className="reviews" onClick={() => navigate(`/groupjoin/${btoa(CryptoJS.AES.encrypt(val.group_id.toString(), secretKey).toString())}`)}>Join Group</span>
                                                    </div>
                                                    : ""}
                                                </div>
                                              </div>
                                            </div>
                                          </div >
                                        </>
                                          : <div className="inspiration-div rounded">
                                            <div className="inspiration-inner-div">
                                              <div className="SummerCamp-2 ">
                                                <div className="d-flex">
                                                  <div className="img-fuild anncounce" style={{ cursor: "pointer" }} >
                                                    <img src={val.send_by_user_photo} className="" alt="user" loading="lazy" />
                                                  </div>
                                                  <div className="summer-text pb-2">
                                                    <h3 >{val?.send_by_name} <span className="ms-2">{getTime(val?.shared_date_time, "accounce")}</span></h3>
                                                    <div>
                                                      {
                                                        val?.fileExtension === "png" ||
                                                          val?.fileExtension === "jpg" ||
                                                          val?.fileExtension === "jpeg" ||
                                                          val?.fileExtension === "PNG" ? <div> <img src={val?.chat_doc} alt="img" loading="lazy" className="announce-img my-1" onClick={() =>
                                                            handleFileDownload(
                                                              val.chat_doc,
                                                              "MatchEd_doc"
                                                            )
                                                          } /> </div> : <div onClick={() =>
                                                            handleFileDownload(
                                                              val.chat_doc,
                                                              "MatchEd_doc"
                                                            )
                                                          }>{val.fileExtension
                                                            ? getFileIcon(val.fileExtension)
                                                            : ""}</div>
                                                      }
                                                    </div>
                                                    <div className="">
                                                      {val?.message}
                                                    </div>
                                                  </div>
                                                  <div className="unpin" onClick={() => { setAnnouncement(true); setMessageId(val.massageUniqueId) }}>
                                                    <img src={unpin} alt="unpin" loading="lazy" />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        }
                                      </>
                                    }
                                  </>
                                );
                              }) :
                                <h1 className="text-center">No Data Found</h1>}
                              {
                                downSpin && <div className="share-spiner">
                                  <FourDotsLoader />
                                </div>
                              }
                            </>
                          }
                        </div>
                        {value === "group_chat" &&
                          <GroupChat groupDetail={groupDetail} adminId={adminId} />}
                      </>
                  }
                  <div className="col-lg-4">
                    <RightSideGroup id={groupId} isMember={ismember} />
                  </div>
                </div>
                <div className={`${ismember ? "" : "blur-overlay"} `}></div>
              </div>
            </div>
            {
              announcement && <Announce messageId={messageId} handleClose={() => setAnnouncement(false)} announcement={announcement} getShreResource={getShreResource} type="no" />
            }
          </div>
        </>
        :
        (groupStatus && groupStatus === "disable") ?
          <>
            <div className="col-lg-10 col-md-12 col-sm-12 padding-row">
              <div className="your-main-group">
                <div className="your-grp row">
                  <div className="col-lg-8 col-12">
                    <div className="chat-group-innere">
                      <div className="the-secret">
                        <h1 className="text-capitalize">Group Disabled By Super Admin</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
          : ""}
    </>);
}
export default GroupYouhaveJoin;
